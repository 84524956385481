export const treatmentLibraryProductSpecFragmentFields = `
registrationNumber
fracNumber
reEntryInterval
treatmentTime
packageSize
packageUnit
packagePrice
packageCurrency
note
manufacturerName
traderRegistrationNumber
fertilizerMaterial {
  id
  name
}
organicCarbon
licenseExpirationDate
usageExpirationDate
chemicalFormulation
lowRisk
hazardNotes
governmentRemarks
fieldsOfUse
ghsNotes
ghsSymbols
ghsSafetyNotes
ghsSignalWord
`;
