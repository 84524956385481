import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import {
	PartnershipLocationStatus,
	TPartnerLocation,
	TPartnershipLocationStatus,
} from 'Models/partnershipCompany/isPartnerLocation';
import Avatar from 'UI/display/Avatar';
import Divider from 'UI/display/Divider';
import Text from 'UI/display/Text';
import IconMore from 'UI/icons/More';
import IconButton from 'UI/inputs/IconButton';
import Box from 'UI/layout/Box';
import Grid from 'UI/layout/Grid';
import Menu from 'UI/navigation/Menu';
import { FarmableColors, FarmablePlaceholdersColors } from 'UI/theme/Colors';

import useSnackbarNotifications from 'Hooks/useSnackbarNotifications';

import GET_PARTNERSHIP_LOCATIONS_DATA from '../../../../../../../features/api/getPartnerLocations';
import DEACTIVATE_PARTNER_LOCATION from '../../../../api/DeactivatePartnerLocation';
import INVITE_LOCATION_TO_COMPANY from '../../../../api/InviteLocationToCompany';

export type TConnectedFarm = Pick<
	TPartnerLocation,
	'name' | 'id' | 'status' | 'farmerEmail'
>;

const colors = Object.values(FarmablePlaceholdersColors);

const partnerLocationStatusToColor = (
	status: TPartnershipLocationStatus
): string => {
	switch (status) {
		case PartnershipLocationStatus.ACTIVE:
			return FarmableColors.GREEN;
		case PartnershipLocationStatus.DEACTIVATED:
			return FarmableColors.BLACK_38;
		case PartnershipLocationStatus.PENDING:
			return FarmableColors.LIGHT_BLUE;
		case PartnershipLocationStatus.DECLINED:
			return FarmableColors.RED;
	}
};

const ellipsisStyles = {
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
};

const prefix = 'partnerDashboardPage.manageFarmsSection';

function FarmListItem(props: TConnectedFarm) {
	const { name, status, farmerEmail, id } = props;
	const { t } = useTranslation();
	const [deactivatePartnerLocation, deactivatePartnerLocationResult] =
		useMutation(DEACTIVATE_PARTNER_LOCATION);

	const [inviteLocationToCompany, inviteLocationToCompanyResult] = useMutation(
		INVITE_LOCATION_TO_COMPANY
	);

	useSnackbarNotifications({
		mutationResult: deactivatePartnerLocationResult,
		messageSuccess: t(`${prefix}.invitationRevoked.success`),
		messageFailure: t(`${prefix}.invitationRevoked.failure`),
	});

	useSnackbarNotifications({
		mutationResult: inviteLocationToCompanyResult,
		messageSuccess: t(`${prefix}.invitationResent.success`),
		messageFailure: t(`${prefix}.invitationResent.failure`),
	});

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLElement>) =>
		setAnchorEl(event.currentTarget);
	const handleClose = () => setAnchorEl(null);
	const handleRevokeInvitation = () => {
		void deactivatePartnerLocation({
			variables: { locationId: id },
			update(cache, { data }) {
				const query = {
					query: GET_PARTNERSHIP_LOCATIONS_DATA,
				};
				const deletedLocation = data?.deactivatePartnerLocation;
				const existingLocations = cache.readQuery(query)?.getPartnerLocations;

				if (existingLocations && deletedLocation) {
					cache.writeQuery({
						...query,
						data: {
							getPartnerLocations: [
								...existingLocations.map((location) =>
									location.id !== id
										? location
										: {
												...location,
												status: PartnershipLocationStatus.DEACTIVATED,
										  }
								),
							],
						},
					});
				}
			},
		});
		handleClose();
	};

	const deactivatedStyle = {
		opacity: 0.5,
	};
	const style =
		status === PartnershipLocationStatus.DEACTIVATED ? deactivatedStyle : null;

	const statusSection = (
		<Box display="flex" flexDirection="row" alignItems="center">
			<Box
				sx={{
					width: '3px',
					height: '0.875rem',
					margin: '3px 6px 3px 0',
					borderRadius: '1.5px',
					backgroundColor: partnerLocationStatusToColor(status),
				}}
			></Box>
			<Text variant="label6" sx={ellipsisStyles}>
				{t(
					`common.partnershipLocationStatus.${PartnershipLocationStatus[status]}`
				)}
			</Text>
		</Box>
	);

	const initials = name
		.split(' ')
		.map((word) => word.charAt(0))
		.join('')
		.toUpperCase();

	const revokeInvitationMenuItem =
		status === PartnershipLocationStatus.PENDING ||
		status === PartnershipLocationStatus.ACTIVE ? (
			<Menu.Item onClick={handleRevokeInvitation}>
				<Text color={FarmableColors.RED}>
					{t(`${prefix}.revokeInvitation`)}
				</Text>
			</Menu.Item>
		) : null;

	const resendInvitationMenuItem =
		status === PartnershipLocationStatus.DECLINED ||
		status === PartnershipLocationStatus.PENDING ||
		status === PartnershipLocationStatus.DEACTIVATED ? (
			<Menu.Item
				onClick={() => {
					void inviteLocationToCompany({
						variables: {
							locationId: id,
						},
					});
					handleClose();
				}}
			>
				<Text>{t(`${prefix}.resendInvitation`)}</Text>
			</Menu.Item>
		) : null;

	// update this constraint while adding new menu options
	const displayMenu = !!revokeInvitationMenuItem || !!resendInvitationMenuItem;

	const menu = (
		<>
			<IconButton onClick={handleClick}>
				<IconMore />
			</IconButton>
			<Menu.Menu
				id="status-select"
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
			>
				{resendInvitationMenuItem}
				{resendInvitationMenuItem && revokeInvitationMenuItem ? (
					<Divider />
				) : null}
				{revokeInvitationMenuItem}
			</Menu.Menu>
		</>
	);

	return (
		<Box p="1rem 0" display="flex" flexDirection="row" alignItems="center">
			<Avatar
				variant="rounded"
				color={colors[(parseInt(id, 10) || 0) % colors.length]}
				sx={{ marginRight: '1rem', ...style }}
			>
				{initials}
			</Avatar>
			<Grid container spacing={1} alignItems="center">
				<Grid item xs={6}>
					<Box>
						<Text variant="body1" sx={{ ...ellipsisStyles, ...style }}>
							{name}
						</Text>
					</Box>
					<Box>
						<Text variant="body2" sx={{ ...ellipsisStyles, ...style }}>
							{farmerEmail}
						</Text>
					</Box>
				</Grid>
				<Grid item xs={3}>
					{statusSection}
				</Grid>
				<Grid item xs={2}>
					<Text variant="label6">{t('labels.notApplicable')}</Text>
				</Grid>
				<Grid
					item
					xs={1}
					display="flex"
					flexDirection="column"
					alignItems="flex-end"
				>
					{displayMenu ? menu : null}
				</Grid>
			</Grid>
		</Box>
	);
}

export default FarmListItem;
